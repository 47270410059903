<template>
  <div>
    <div class="py-2 text-general">
      <div class="p-2 d-flex justify-content-between">
        <label class="header-text text-main">รายการขาย</label>
        <b-button class="d-flex align-items-center bg-main" @click="showFilter">
          <font-awesome-icon icon="filter" title="filter-btn" />
          <span class="mx-2 font-weight-bold d-md-down-none">กรองข้อมูล</span>
          <font-awesome-icon
            icon="chevron-circle-left"
            title="filter-btn"
            class="d-md-down-none"
          />
        </b-button>
      </div>
      <section class="d-md-none" v-if="!isBusy">
        <div class="bg-sub p-3">รายการขาย</div>
        <div v-if="!items.length" class="text-center p-3 border-bottom">
          ไม่มีข้อมูล
        </div>
        <div v-for="row of items" :key="row.id" class="p-3 border-bottom">
          <div class="row align-items-center">
            <div class="col-6 font-weight-bold">{{ row.ticket_no }}</div>
            <div class="col-6 text-right">
              <router-link class="link-to" :to="`sale-order/detail/${row.id}`"
                >ดูรายละเอียด</router-link
              >
            </div>
            <div class="col-6 font-weight-bold">
              {{ row.first_name || "-" }} {{ row.last_name }}
            </div>
            <div class="col-6 text-right">
              {{ row.grand_total | numeral("0,0.00") }} บาท
            </div>
            <div class="col-6 font-weight-bold">({{ row.telephone }})</div>
            <div class="col-6 text-right">{{ row.type_name || "-" }}</div>
            <div class="col-6 text-gray-2">
              {{ row.created_time | moment("DD MMM YYYY (HH:mm)") }}
            </div>
            <div class="col-6 text-right text-gray-2">
              Sale ({{ row.sale_no || "-" }})
            </div>
            <div class="col-6 text-gray-2">{{ row.branch_name || "-" }}</div>
            <div class="col-6 text-right text-gray-2">
              <font-awesome-icon
                class="text-main cursor-pointer"
                icon="trash-alt"
                size="xs"
                title="Delete"
                @click="handleDelete(data.item)"
              />
            </div>
          </div>
        </div>
        <b-pagination
          size="sm"
          limit="4"
          v-model="filter.page"
          :total-rows="rows"
          :per-page="filter.take"
          class="m-md-0 page-item mt-3"
          @change="pagination"
          align="center"
        ></b-pagination>
      </section>
      <div class="d-sm-down-none">
        <b-table
          responsive
          class="mt-2 text-center table-list mb-0 table-p-th"
          hover
          :tbody-tr-class="trTableReport"
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="ไม่มีรายการข้อมูลการซื้อ"
        >
          <template v-slot:head(ticket_no)="">
            <p class="mb-0">หมายเลข Ticket</p>
            <p class="mb-0">(Ticket No)</p>
          </template>
          <template v-slot:head(first_name)="">
            <p class="mb-0">ชื่อสมาชิก</p>
            <p class="mb-0">(Name)</p>
          </template>
          <template v-slot:head(telephone)="">
            <p class="mb-0">หมายเลขโทรศัพท์</p>
            <p class="mb-0">(Phone no.)</p>
          </template>
          <template v-slot:head(branch_name)="">
            <p class="mb-0">สาขา</p>
            <p class="mb-0">(Branch)</p>
          </template>
          <template v-slot:head(grand_total)="">
            <p class="mb-0">ยอดขาย</p>
            <p class="mb-0">(Grand Total)</p>
          </template>
          <template v-slot:head(sale_no)="">
            <p class="mb-0">รหัสพนักงาน</p>
            <p class="mb-0">(Sale No)</p>
          </template>
          <template v-slot:head(created_time)="">
            <p class="mb-0">วัน/เวลา</p>
            <p class="mb-0">(Date/Time)</p>
          </template>
          <template v-slot:head(status)="">
            <p class="mb-0">สถานะ</p>
            <p class="mb-0">(Status)</p>
          </template>
          <template v-slot:cell(ticket_no)="data">
            <div>
              <label>{{ data.item.ticket_no || "-" }}</label>
            </div>
          </template>
          <template v-slot:cell(first_name)="data">
            <div>
              <label
                >{{ data.item.first_name || "-" }}
                {{ data.item.last_name }}</label
              >
            </div>
          </template>
          <template v-slot:cell(telephone)="data">
            <div>
              <label>{{ data.item.telephone || "-" }}</label>
            </div>
          </template>
          <template v-slot:cell(branch_name)="data">
            <div>
              <label>{{ data.item.branch_name || "-" }}</label>
            </div>
          </template>
          <template v-slot:cell(grand_total)="data">
            <div>
              <label>{{ data.item.grand_total | numeral("0,0.00") }}</label>
            </div>
          </template>
          <template v-slot:cell(sale_no)="data">
            <div>
              <label>{{ data.item.sale_no || "-" }}</label>
            </div>
          </template>
          <template v-slot:cell(created_time)="data">
            <div v-if="data.item.created_time">
              <label>{{
                new Date(data.item.created_time) | moment($formatDateTime)
              }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(status)="data">
            <div v-if="data.item.status">
              <label :class="data.item.status_id != 1 ? 'text-danger' : ''">{{
                data.item.status
              }}</label>
            </div>
            <div v-else><p class="m-0">-</p></div>
          </template>
          <template v-slot:cell(action)="data">
            <div class="d-flex align-items-center justify-content-center">
              <router-link :to="`sale-order/detail/${data.item.id}`">
                <font-awesome-icon class="text-main" icon="eye" size="xs" />
              </router-link>
              <font-awesome-icon
                class="text-main ml-2 cursor-pointer"
                icon="trash-alt"
                size="xs"
                title="Delete"
                @click="handleDelete(data.item)"
              />
            </div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
        <div class="mt-2">
          <b-pagination
            :show="!isBusy"
            size="sm"
            limit="4"
            v-model="filter.page"
            :total-rows="rows"
            :per-page="filter.take"
            class="m-md-0 page-item"
            @change="pagination"
            align="center"
          ></b-pagination>
        </div>
      </div>
      <SlideFilter
        ref="SlideFilter"
        :filter="filter"
        :branchOption="branchOption"
        @onSubmitFilter="getList"
      />
      <ModalLoading ref="modalLoading" :hasClose="false" />
      <ModalAlertConfirm
        msg="คุณต้องการลบรายการนี้ ?"
        :text="modalMessage"
        colorBtnCancel="outline"
        colorBtnConfirm="secondary"
        btnCancel="ยกเลิก"
        btnConfirm="ยืนยัน"
        ref="modalAlertConfirm"
        @confirm="deleteItem"
      />
    </div>
  </div>
</template>

<script>
import SlideFilter from "./components/SlideFilter.vue";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
// import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

export default {
  name: "ReportIndex",
  components: {
    SlideFilter,
    ModalLoading,
    ModalAlertConfirm,
  },
  data() {
    return {
      isBusy: false,
      modalMessage: "",
      deleteId: 0,
      filter: {
        search: "",
        page: 1,
        take: 8,
        member_id: "",
        member_name: "",
        telephone: "",
        ticket_no: "",
        sales_no: "",
        start_datetime: null,
        end_datetime: null,
        branch_id: 0,
      },
      rows: 0,
      items: [],
      fields: [
        {
          key: "ticket_no",
          label: "หมายเลขใบเสร็จ",
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "first_name",
          label: `ชื่อสมาชิก`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "telephone",
          label: `หมายเลขโทรศัพท์`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "branch_name",
          label: `สาขา`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "grand_total",
          label: `ยอดขาย`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "sale_no",
          label: `รหัสพนักงาน`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "created_time",
          label: `วัน/เวลา`,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
        {
          key: "action",
          label: ``,
          class: "text-nowrap",
          thStyle: {
            "min-width": "150px",
            color: this.$store.state.colorPrimary,
            "background-color": this.LightenDarkenColor(
              this.$store.state.colorSecondary,
              90
            ),
            opacity: "0.81",
            "border-bottom": `2px solid ${this.$store.state.colorPrimary}`,
          },
        },
      ],
      trTableReport: {
        "border-bottom": "1px solid #D9D9D9",
      },
      branchOption: [
        {
          name: "เลือกสาขา",
          id: 0,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ salesNo: "getSalesNo", branchId: "getBranchId" }),
  },
  async created() {
    this.getBranch();
    await this.init();
  },
  methods: {
    async init() {
      await this.$store.dispatch("setMainLoading", true);
      await this.getList();
      await this.$store.dispatch("setMainLoading", false);
    },
    async getList() {
      this.isBusy = true;

      this.filter.branch_id = this.$cookies.get("branch_id");

      await this.axios
        .post(`${this.$baseUrlSaleOrder}/saleorder/search`, this.filter)
        .then((data) => {
          if (data.result == 1) {
            this.items = data.detail.datas;
            this.rows = data.detail.total;
          }
          this.isBusy = false;
        });
    },
    async getBranch() {
      await this.axios
        .get(`${this.$baseUrlSaleOrder}/customer/Branch`)
        .then((data) => {
          if (data.result == 1) {
            this.branchOption = this.branchOption.concat(data.detail);
          }
        });
    },
    showFilter() {
      this.$refs.SlideFilter.show();
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getList();
    },
    LightenDarkenColor(col, amt) {
      var usePound = false;

      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }

      var num = parseInt(col, 16);

      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
    handleDelete(item) {
      this.modalMessage = item.ticket_no;
      this.deleteId = item.id;
      this.$refs.modalAlertConfirm.show();
    },
    async deleteItem() {
      this.$refs.modalAlertConfirm.hide();
      await this.axios
        .delete(`${this.$baseUrlSaleOrder}/saleOrder/${this.deleteId}`)
        .then(async (data) => {
          if (data.result == 1) {
            this.successAlert(data.message);
            await this.init();
          } else {
            this.warningAlert(data.message);
          }
        })
        .catch((err) => {
          this.warningAlert(err.message);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-slide-btn {
  background-color: #e8e9ec;
  border: none;
}
.link-to {
  text-decoration: underline;
  color: #7c7a7a;
}
.text-main {
  color: var(--primary-color) !important;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
