<template>
  <div>
    <b-sidebar
      id="sidebar-filter"
      v-model="showSlide"
      backdrop
      shadow
      right
      aria-labelledby="sidebar-filter-title"
      @hidden="hide()"
    >
      <template>
        <div>
          <div class="p-2 d-flex justify-content-between align-items-center">
            <label class="header-text">กรองข้อมูล</label>
            <label class="pointer" @click="hide"
              ><b-icon icon="x-circle-fill"></b-icon
            ></label>
          </div>
          <div>
            <div class="p-2">
              <InputText
                textFloat="หมายเลขสมาชิก (Member ID)"
                v-model="filter.memberId"
                class="mb-2"
                placeholder="Member ID"
                type="text"
              />
              <InputText
                textFloat="ชื่อสมาชิก (Member Name)"
                v-model="filter.member_name"
                class="mb-2"
                placeholder="Name"
                type="text"
              />
              <InputText
                textFloat="หมายเลขโทรศัพท์ (Phone No.)"
                v-model="filter.telephone"
                class="mb-2"
                placeholder="Phone No."
                type="text"
              />
              <InputText
                textFloat="หมายเลขใบเสร็จ (POS Ref No.)"
                v-model="filter.ticket_no"
                class="mb-2"
                placeholder="POS Ref No."
                type="text"
              />
              <div class="mb-2">
                <InputDatePickerFilter
                  textFloat="วันที่เริ่มต้น"
                  name="start_datetime"
                  ref="start_datetime"
                  placeholder="DD/MM/YYYY"
                  :max-datetime="filter.end_datetime || initFilterDate.MaxDate"
                  v-model="filter.start_datetime"
                  @input="changeStartDate"
                />
              </div>
              <div class="mb-2">
                <InputDatePickerFilter
                  textFloat="วันที่สิ้นสุด"
                  name="end_datetime"
                  ref="end_datetime"
                  placeholder="DD/MM/YYYY"
                  :min-datetime="filter.start_datetime"
                  :max-datetime="initFilterDate.MaxDate"
                  v-model="filter.end_datetime"
                  @input="onChangeEndDate"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="text-center p-2">
          <p @click="clearFilter"><u>ล้างตัวกรอง</u></p>
          <b-button class="radius-btn bg-main mt-2" block @click="submitForm"
            ><span>ค้นหา</span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import InputText from '@/components/inputs/InputText';
import InputDatePickerFilter from '@/components/inputs/InputDatePickerFilter';

export default {
  name: 'SlideFilter',
  components: { InputText, InputDatePickerFilter },
  props: {
    filter: {
      type: Object,
      isRequired: true
    },
    branchOption: {
      type: Array,
      isRequired: false
    }
  },
  data() {
    return {
      showSlide: false,
      initFilterDate: {
        MinDate: '',
        MaxDate: ''
      }
    };
  },
  created() {
    let toDay = this.$moment().format('YYYY-MM-DDT00:00:00');
    this.initFilterDate.MaxDate = toDay;
  },
  methods: {
    show() {
      this.showSlide = true;
    },
    hide() {
      this.showSlide = false;
    },
    changeStartDate() {
      if (!this.filter.end_datetime)
        this.filter.end_datetime = this.initFilterDate.MaxDate;
    },
    onChangeEndDate() {
      if (!this.filter.start_datetime)
        this.filter.start_datetime = this.filter.end_datetime;
    },
    async clearFilter() {
      this.filter.search = '';
      this.filter.page = 1;
      this.filter.take = 8;

      this.filter.memberId = '';
      this.filter.member_name = '';
      this.filter.telephone = '';
      this.filter.ticket_no = '';
      this.filter.start_datetime = '';
      this.filter.end_datetime = '';
      // this.filter.sales_no = "";
      this.filter.branch_id = 0;

      this.$emit('onSubmitFilter');
    },
    async submitForm() {
      await this.$emit('onSubmitFilter');
      this.hide();
    }
  }
};
</script>
